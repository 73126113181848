var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-card',{staticClass:"mx-auto my-3 px-5 py-3",attrs:{"max-width":"1050px"}},[(_vm.trackingSummary)?_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-toolbar-title',{staticClass:"p-3 pt-0 font-weight-bold"},[_vm._v("Resumen")])],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" HH del día: ")]),_c('v-card-text',{},[_c('div',[_vm._v(_vm._s(_vm.trackingSummary.hours_today))])])],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" HH de la semana: ")]),_c('v-card-text',{},[_c('div',[_vm._v(_vm._s(_vm.trackingSummary.hours_week))])])],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" HH del mes: ")]),_c('v-card-text',{},[_c('div',[_vm._v(_vm._s(_vm.trackingSummary.hours_month))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","sm":"12"}},[_c('v-toolbar-title',{staticClass:"text-center p-3 mb-3 font-weight-bold text-xs"},[_vm._v("Estadisticas de HH por proyecto ")]),_c('v-row',{staticClass:"px-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtro por año","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click":_vm.saveYear},model:{value:(_vm.filters.year),callback:function ($$v) {_vm.$set(_vm.filters, "year", $$v)},expression:"filters.year"}},'v-text-field',attrs,false),on))]}}],null,false,3014379110),model:{value:(_vm.dateYear),callback:function ($$v) {_vm.dateYear=$$v},expression:"dateYear"}},[_c('v-date-picker',{ref:"pickerYear",attrs:{"no-title":"","scrollable":"","max":new Date().toISOString().split('T')[0]},on:{"click:year":function (year) { return _vm.saveYear(year); }},model:{value:(_vm.filters.year),callback:function ($$v) {_vm.$set(_vm.filters, "year", $$v)},expression:"filters.year"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtro por mes","prepend-icon":"mdi-calendar","readonly":"","disabled":!_vm.filters.year,"clearable":""},on:{"click":_vm.saveMonth},model:{value:(_vm.filters.monthFormatted),callback:function ($$v) {_vm.$set(_vm.filters, "monthFormatted", $$v)},expression:"filters.monthFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2159325217),model:{value:(_vm.dateMonth),callback:function ($$v) {_vm.dateMonth=$$v},expression:"dateMonth"}},[_c('v-date-picker',{ref:"pickerMonth",attrs:{"id":"pickerMonth","no-title":"","type":"month","scrollable":""},on:{"click:month":function (month) { return _vm.saveMonth(month); }},model:{value:(_vm.filters.month),callback:function ($$v) {_vm.$set(_vm.filters, "month", $$v)},expression:"filters.month"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-autocomplete',{attrs:{"label":"Filtro por centro de costo","items":_vm.projects,"clearable":"","item-text":"custom_code","item-value":"code"},model:{value:(_vm.filters.project_code),callback:function ($$v) {_vm.$set(_vm.filters, "project_code", $$v)},expression:"filters.project_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-autocomplete',{attrs:{"label":"Filtro por profesional","items":_vm.professionals,"clearable":"","item-text":"name","item-value":"email"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('p',{staticClass:"m-3"},[_vm._v(" "+_vm._s(item.name)+" "),_c('small',[_vm._v("("+_vm._s(item.email)+")")]),_c('br'),_c('span',{staticStyle:{"font-size":"12px","color":"gray"}},[_vm._v(_vm._s(item.rut ? item.rut : "Sin RUT registrado"))])])]}}],null,false,1561672416),model:{value:(_vm.filters.professional),callback:function ($$v) {_vm.$set(_vm.filters, "professional", $$v)},expression:"filters.professional"}})],1)],1),(_vm.ifCountHHProjects)?_c('bar-chart',{ref:"chartTrackingSummary"}):_c('v-toolbar-title',{staticClass:"d-flex mt-5 align-items-center justify-content-center"},[_c('p',[_vm._v(" ¡ Sin HH actualmente para mostrar ! ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}})],1):_c('section',{staticClass:"text-center pt-5"},[_c('v-progress-circular',{staticClass:"mt-5",attrs:{"color":"primary","indeterminate":"","size":"64"}}),_c('p',{staticClass:"mt-2"},[_vm._v("Cargando..")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }