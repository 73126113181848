var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-container',{staticClass:"p-3"},[_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-3"},[_vm._v(" Time Sheet Flux ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"active-class":"active","to":item.to,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('card-info-user',{attrs:{"infoUser":_vm.currentInfoUser,"userActiveContract":_vm.userActiveContract}}),_c('br'),_c('time-sheet-summary',{ref:"timeSheetSummary"}),_c('br'),_c('v-card',{staticClass:"mx-auto my-3",attrs:{"max-width":"1050px"}},[_c('v-data-table',{staticClass:"elevation-3 px-3 pt-4",attrs:{"headers":_vm.tableHeaders,"items":_vm.wrappedTrackingItems,"items-per-page":20,"loading":_vm.loadingTrackings,"item-key":"id","hide-default-footer":"","no-data-text":"Sin HH registradas actualmente"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"py-4",attrs:{"flat":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"w-25 mt-5",attrs:{"label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filters.dateFormatted),callback:function ($$v) {_vm.$set(_vm.filters, "dateFormatted", $$v)},expression:"filters.dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().split('T')[0]},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.filters.dateTracking),callback:function ($$v) {_vm.$set(_vm.filters, "dateTracking", $$v)},expression:"filters.dateTracking"}})],1),_c('v-btn',{staticClass:"mx-2 mt-3",attrs:{"color":"blue darken-3","disabled":!_vm.filters.dateTracking,"x-small":""},on:{"click":function($event){_vm.filters.dateTracking = null}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Limpiar ")])]),_c('v-divider',{staticClass:"mx-5",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"green darken-3","dark":false,"small":""},on:{"click":_vm.downloadExcelSummaryTracking}},[_c('span',{staticClass:"text-white"},[_vm._v(" Descargar reporte general (.xlsx) ")])]),(_vm.rolModuloPermiso.escritura)?_c('v-btn',{attrs:{"color":"blue darken-3","disabled":_vm.existDraftElementOrEditElement,"dark":false,"small":""},on:{"click":_vm.addTrackingItemDraft}},[_c('span',{staticClass:"text-white"},[_vm._v(" Agregar nuevo ")])]):_vm._e()],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.isDraft || item.edit)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.dateFormatted),callback:function ($$v) {_vm.$set(_vm.form, "dateFormatted", $$v)},expression:"form.dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenuForm),callback:function ($$v) {_vm.dateMenuForm=$$v},expression:"dateMenuForm"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().split('T')[0]},on:{"input":function($event){_vm.dateMenuForm = false}},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1):_c('span',[_vm._v(_vm._s(_vm.formatDateToHumanMethod(item.date)))])]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [(item.isDraft || item.edit)?_c('v-autocomplete',{attrs:{"items":_vm.projects,"item-text":"custom_code","item-value":"code"},model:{value:(_vm.form.project_code),callback:function ($$v) {_vm.$set(_vm.form, "project_code", $$v)},expression:"form.project_code"}}):_c('span',[_vm._v(_vm._s(item.project.code))])]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [(item.isDraft || item.edit)?_c('v-autocomplete',{attrs:{"items":_vm.departments,"item-text":"name","item-value":"id"},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}):_c('span',[_vm._v(_vm._s(item.revision.activity.department.name))])]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [(item.isDraft || item.edit)?_c('v-autocomplete',{attrs:{"items":_vm.activities,"item-text":"name","item-value":"id","disabled":!_vm.form.area},model:{value:(_vm.form.activity),callback:function ($$v) {_vm.$set(_vm.form, "activity", $$v)},expression:"form.activity"}}):_c('span',[_vm._v(_vm._s(item.revision.activity.name))])]}},{key:"item.revision",fn:function(ref){
var item = ref.item;
return [(item.isDraft || item.edit)?_c('v-autocomplete',{attrs:{"items":_vm.revisions,"item-text":"name","item-value":"id","disabled":!_vm.form.activity},model:{value:(_vm.form.revision_id),callback:function ($$v) {_vm.$set(_vm.form, "revision_id", $$v)},expression:"form.revision_id"}}):_c('span',[_vm._v(_vm._s(item.revision.name))])]}},{key:"item.hours",fn:function(ref){
var item = ref.item;
return [(item.isDraft || item.edit)?_c('v-text-field',{attrs:{"type":"number","min":1},model:{value:(_vm.form.hours),callback:function ($$v) {_vm.$set(_vm.form, "hours", $$v)},expression:"form.hours"}}):_c('span',[_vm._v(_vm._s(item.hours))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isDraft),expression:"item.isDraft"}],attrs:{"disabled":!_vm.formIsComplete,"icon":"","color":"primary"},on:{"click":_vm.createNewTracking}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isDraft),expression:"item.isDraft"}],staticClass:"mx-2",attrs:{"icon":"","color":"red darken-3"},on:{"click":function($event){return _vm.deleteTrackingDraft(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!item.isDraft && !item.edit),expression:"!item.isDraft && !item.edit"}],attrs:{"icon":"","color":item.comment ? 'primary' : 'secondary',"disabled":!item.comment},on:{"click":function($event){return _vm.showCurrentTrackingComment(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Comentarios")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
                    _vm.rolModuloPermiso.escritura && !item.isDraft && !item.edit
                  ),expression:"\n                    rolModuloPermiso.escritura && !item.isDraft && !item.edit\n                  "}],attrs:{"icon":"","color":"red darken-3"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
                    !item.isDraft && !item.edit && !_vm.currentTrackingToEdit
                  ),expression:"\n                    !item.isDraft && !item.edit && !currentTrackingToEdit\n                  "}],attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.setEditTrackingItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.edit),expression:"item.edit"}],attrs:{"disabled":!_vm.formIsComplete,"icon":"","color":"primary"},on:{"click":_vm.editTracking}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Finalizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.edit),expression:"item.edit"}],staticClass:"mx-2",attrs:{"icon":"","color":"red darken-3"},on:{"click":function($event){return _vm.cancelEditTracking(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancelar")])])],1)]}}])})],1),_c('tracking-comment',{attrs:{"currentTrackingComment":_vm.currentTrackingComment,"dialogShowTrackingComment":_vm.dialogShowTrackingComment,"closeModalShowTrackingComment":_vm.closeModalShowTrackingComment}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }