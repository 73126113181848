<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-container class="p-3">
      <section class="d-flex flex-column flex-sm-row justify-content-between">
        <h2 class="ml-1 my-3">
          Time Sheet Flux
        </h2>
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              active-class="active"
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </section>
      <card-info-user
        :infoUser="currentInfoUser"
        :userActiveContract="userActiveContract"
      ></card-info-user>
      <br />
      <time-sheet-summary ref="timeSheetSummary"></time-sheet-summary>
      <br />
      <v-card max-width="1050px" class="mx-auto my-3">
        <v-data-table
          class="elevation-3 px-3 pt-4"
          :headers="tableHeaders"
          :items="wrappedTrackingItems"
          :items-per-page="20"
          :loading="loadingTrackings"
          item-key="id"
          hide-default-footer
          no-data-text="Sin HH registradas actualmente"
        >
          <template v-slot:top>
            <v-toolbar flat class="py-4">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.dateFormatted"
                    class="w-25 mt-5"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.dateTracking"
                  :max="new Date().toISOString().split('T')[0]"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                color="blue darken-3"
                :disabled="!filters.dateTracking"
                @click="filters.dateTracking = null"
                x-small
                class="mx-2 mt-3"
              >
                <span class="text-white">
                  Limpiar
                </span>
              </v-btn>
              <v-divider class="mx-5" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-3"
                @click="downloadExcelSummaryTracking"
                :dark="false"
                class="mx-3"
                small
              >
                <span class="text-white">
                  Descargar reporte general (.xlsx)
                </span>
              </v-btn>

              <v-btn
                color="blue darken-3"
                v-if="rolModuloPermiso.escritura"
                :disabled="existDraftElementOrEditElement"
                @click="addTrackingItemDraft"
                :dark="false"
                small
              >
                <span class="text-white">
                  Agregar nuevo
                </span>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.date="{ item }">
            <v-menu
              v-if="item.isDraft || item.edit"
              v-model="dateMenuForm"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.dateFormatted"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date"
                :max="new Date().toISOString().split('T')[0]"
                @input="dateMenuForm = false"
              ></v-date-picker>
            </v-menu>
            <span v-else>{{ formatDateToHumanMethod(item.date) }}</span>
          </template>
          <template v-slot:item.project="{ item }">
            <v-autocomplete
              v-if="item.isDraft || item.edit"
              v-model="form.project_code"
              :items="projects"
              item-text="custom_code"
              item-value="code"
            ></v-autocomplete>
            <span v-else>{{ item.project.code }}</span>
          </template>
          <template v-slot:item.area="{ item }">
            <v-autocomplete
              v-if="item.isDraft || item.edit"
              v-model="form.area"
              :items="departments"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <span v-else>{{ item.revision.activity.department.name }}</span>
          </template>
          <template v-slot:item.activity="{ item }">
            <v-autocomplete
              v-if="item.isDraft || item.edit"
              v-model="form.activity"
              :items="activities"
              item-text="name"
              item-value="id"
              :disabled="!form.area"
            ></v-autocomplete>
            <span v-else>{{ item.revision.activity.name }}</span>
          </template>
          <template v-slot:item.revision="{ item }">
            <v-autocomplete
              v-if="item.isDraft || item.edit"
              v-model="form.revision_id"
              :items="revisions"
              item-text="name"
              item-value="id"
              :disabled="!form.activity"
            ></v-autocomplete>
            <span v-else>{{ item.revision.name }}</span>
          </template>
          <template v-slot:item.hours="{ item }">
            <v-text-field
              v-if="item.isDraft || item.edit"
              v-model="form.hours"
              type="number"
              :min="1"
            ></v-text-field>
            <span v-else>{{ item.hours }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-items-center justify-content-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.isDraft"
                    @click="createNewTracking"
                    :disabled="!formIsComplete"
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-check
                    </v-icon>
                  </v-btn>
                </template>
                <span>Agregar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.isDraft"
                    @click="deleteTrackingDraft(item.id)"
                    icon
                    class="mx-2"
                    color="red darken-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="!item.isDraft && !item.edit"
                    icon
                    :color="item.comment ? 'primary' : 'secondary'"
                    @click="showCurrentTrackingComment(item)"
                    :disabled="!item.comment"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-alert-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>Comentarios</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="
                      rolModuloPermiso.escritura && !item.isDraft && !item.edit
                    "
                    @click="deleteItem(item)"
                    icon
                    color="red darken-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="
                      !item.isDraft && !item.edit && !currentTrackingToEdit
                    "
                    @click="setEditTrackingItem(item)"
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.edit"
                    @click="editTracking"
                    :disabled="!formIsComplete"
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-check
                    </v-icon>
                  </v-btn>
                </template>
                <span>Finalizar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.edit"
                    @click="cancelEditTracking(item)"
                    icon
                    class="mx-2"
                    color="red darken-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cancelar</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card>
      <!-- Comentario del tracking -->
      <tracking-comment
        :currentTrackingComment="currentTrackingComment"
        :dialogShowTrackingComment="dialogShowTrackingComment"
        :closeModalShowTrackingComment="closeModalShowTrackingComment"
      ></tracking-comment>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { formatDateToHuman } from "@/helpers/common";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import TrackingComment from "./components/TrackingComment.vue";
import CardInfoUser from "./components/CardInfoUser.vue";
import swal2 from "sweetalert2";
import TimeSheetSummary from "./components/TimeSheetSummary.vue";
import moment from "moment";
import { MAP_MONTHS } from "@/constants/common";
export default {
  components: { TrackingComment, CardInfoUser, TimeSheetSummary },
  mounted() {
    let permissions = permiso.obtenerPermisos(this.user, "traking_hh");
    this.rolModuloPermiso = permissions;
    this.getAllProjects();
    this.getDepartments();
  },
  destroyed() {
    this.cleanTrackings();
  },
  data() {
    return {
      loading: false,
      loadingTrackings: false,
      dialogShowTrackingComment: false,
      dateMenu: false,
      dateMenuForm: false,
      currentTrackingDraftId: null,
      currentTrackingToEdit: null,
      form: {
        date: new Date().toISOString().split("T")[0],
        dateFormatted: formatDateToHuman(
          new Date().toISOString().split("T")[0]
        ),
        project_code: null,
        area: "",
        activity: "",
        revision_id: null,
        hours: null,
        comment: null,
      },
      filters: {
        dateTracking: new Date().toISOString().split("T")[0],
        dateFormatted: formatDateToHuman(
          new Date().toISOString().split("T")[0]
        ),
      },
      currentInfoUser: null,
      userActiveContract: null,
      currentTrackingComment: null,
      breadcrumb: [
        {
          text: "Proyectos",
          to: { name: "proyectos" },
        },
        {
          text: "Time Sheet Flux",
          disabled: true,
        },
      ],
      tableHeaders: [
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "date",
        },
        {
          text: "Centro Costo",
          align: "center",
          sortable: true,
          value: "project",
        },
        {
          text: "Area",
          align: "center",
          sortable: true,
          value: "area",
        },
        {
          text: "Actividad",
          align: "center",
          sortable: true,
          value: "activity",
        },
        {
          text: "Revisión",
          align: "center",
          sortable: true,
          value: "revision",
        },
        {
          text: "HH",
          align: "center",
          sortable: true,
          value: "hours",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
      //Permisos
      rolModuloPermiso: {},
    };
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      users: "user/users",
      trackings: "tracking/trackings",
      projects: "project/projects",
      departments: "tracking/departments",
      activities: "tracking/activities",
      revisions: "tracking/revisions",
    }),
    wrappedTrackingItems() {
      return [...this.trackings];
    },
    existDraftElementOrEditElement() {
      return this.wrappedTrackingItems.some(
        (item) => item.isDraft || item.edit
      );
    },
    formIsComplete() {
      return (
        this.form.project_code &&
        this.form.hours &&
        this.form.revision_id &&
        this.form.date
      );
    },
  },
  methods: {
    ...authMethods,
    ...mapActions({
      getDepartments: "tracking/getDepartments",
      getActivities: "tracking/getActivities",
      getRevisions: "tracking/getRevisions",
      getAllProjects: "project/getAllProjects",
      getUserActiveContract: "user/getUserActiveContract",
      getUserFromEmail: "user/getUserFromEmail",
      getTrackings: "tracking/getTrackings",
      cleanTrackings: "tracking/cleanTrackings",
      createTracking: "tracking/createTracking",
      updateTracking: "tracking/updateTracking",
      deleteTracking: "tracking/deleteTracking",
      setTrackingDraft: "tracking/setTrackingDraft",
      setEditTracking: "tracking/setEditTracking",
      spliceTrackingDraft: "tracking/spliceTrackingDraft",
      downloadExcelTracking: "tracking/downloadExcelTracking",
    }),
    async getTrackingData() {
      this.loadingTrackings = true;
      await this.getTrackings({
        user_email: this.user.email,
        date: this.filters.dateTracking,
      });
      this.loadingTrackings = false;
    },
    async getUserActiveContractData() {
      this.userActiveContract = await this.getUserActiveContract({
        user_email: this.user.email,
        date: this.filters.dateTracking,
      });
    },
    async setCurrentInfoUser() {
      this.currentInfoUser = await this.getUserFromEmail({
        email: this.user.email,
      });
    },
    showCurrentTrackingComment(item) {
      (this.currentTrackingComment = item.comment),
        (this.dialogShowTrackingComment = true);
    },
    closeModalShowTrackingComment($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogShowTrackingComment = false;
      }
    },
    addTrackingItemDraft() {
      this.currentTrackingDraftId = new Date().getTime();
      this.setTrackingDraft({
        id: this.currentTrackingDraftId,
        date: null,
        hours: null,
        area: null,
        activity: null,
        revision_id: null,
        project_code: null,
        comment: null,
        isDraft: true,
      });
    },
    setEditTrackingItem(tracking) {
      this.currentTrackingToEdit = tracking;
      this.form = {
        date: tracking.date,
        dateFormatted: formatDateToHuman(tracking.date),
        project_code: tracking.project.code,
        area: tracking.revision.activity.department.id,
        activity: tracking.revision.activity.id,
        revision_id: tracking.revision.id,
        hours: tracking.hours,
        comment: null,
      };
      this.setEditTracking({ id: tracking.id, edit: true });
    },
    cancelEditTracking(tracking) {
      this.currentTrackingToEdit = null;
      this.resetForm();
      this.setEditTracking({ id: tracking.id, edit: false });
    },
    deleteTrackingDraft(id) {
      this.currentTrackingDraftId = null;
      this.spliceTrackingDraft(id);
    },
    async createNewTracking() {
      this.loading = true;
      const resp = await this.createTracking({
        date: this.form.date,
        hours: this.form.hours,
        date: this.form.date,
        created_by: this.user.email,
        user_email: this.user.email,
        revision_id: this.form.revision_id,
        project_code: this.form.project_code,
        comment: null,
      });
      if (resp.status === 200) {
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "HH agregadas correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        this.spliceTrackingDraft(this.currentTrackingDraftId);
        this.currentTrackingDraftId = null;
        this.$refs.timeSheetSummary.getTrackingSummaryData();
        this.resetForm();
      }
      this.loading = false;
    },
    async editTracking() {
      this.loading = true;
      const resp = await this.updateTracking({
        id: this.currentTrackingToEdit.id,
        date: this.form.date,
        hours: this.form.hours,
        date: this.form.date,
        created_by: this.user.email,
        revision_id: this.form.revision_id,
        project_code: this.form.project_code,
        comment: null,
      });
      if (resp.status === 200) {
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "HH actualizadas correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        this.currentTrackingToEdit = null;
        this.getTrackingData();
        this.$refs.timeSheetSummary.getTrackingSummaryData();
        this.resetForm();
      }
      this.loading = false;
    },
    resetForm() {
      this.form = {
        date: null,
        project_code: null,
        area: null,
        activity: "",
        revision_id: null,
        hours: null,
        comment: "",
      };
    },
    deleteItem({ id }) {
      let self = this;
      swal2
        .fire({
          icon: "error",
          title: `Deseas eliminar estas HH ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.loading = true;
            const resp = await self.deleteTracking({
              tracking_id: id,
              user: self.user.email,
            });
            if (resp.status === 200) {
              self.$swal({
                icon: "success",
                title: "Excelente",
                text: "HH eliminadas correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              self.getTrackingData();
              self.$refs.timeSheetSummary.getTrackingSummaryData();
            }
            self.loading = false;
          }
        });
    },
    formatDateToHumanMethod(date) {
      return formatDateToHuman(date);
    },
    async downloadExcelSummaryTracking() {
      this.loading = true;
      let date = this.filters.dateTracking
        ? this.filters.dateTracking
        : moment().format("YYYY-MM-DD");
      const response = await this.downloadExcelTracking({
        date,
      });
      if (response.status == 404) {
        this.loading = false;
        let month = moment(date).month() + 1;
        return this.$swal({
          icon: "info",
          title: "Atención",
          text: `No hay horas cargadas en el mes de ${
            MAP_MONTHS[month < 10 ? "0" + month.toString() : month.toString()]
          }`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `timesheet_resumen_${date}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$swal({
        icon: "success",
        title: "Excelente",
        text: `Reporte generado y descargado correctamente`,
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 5000,
        timerProgressBar: true,
      });
      this.loading = false;
    },
  },
  watch: {
    "filters.dateTracking": function(date) {
      this.filters.dateFormatted = date ? formatDateToHuman(date) : null;
      this.getTrackingData();
    },
    "form.date": function(date) {
      this.form.dateFormatted = date ? formatDateToHuman(date) : null;
    },
    "form.area": async function(newArea) {
      await this.getActivities({ department_id: newArea });
      if (this.currentTrackingToEdit) {
        this.form.activity = this.currentTrackingToEdit.revision.activity.id;
      } else {
        this.form.activity = null;
      }
    },
    "form.activity": async function(newActivity) {
      await this.getRevisions({ activities_id: newActivity });
      if (this.currentTrackingToEdit) {
        this.form.revision_id = this.currentTrackingToEdit.revision.id;
      } else {
        this.form.revision_id = null;
      }
    },
    user: {
      immediate: true,
      handler: function(newUser) {
        if (newUser) {
          this.setCurrentInfoUser();
          this.getTrackingData();
          this.getUserActiveContractData();
        }
      },
    },
  },
};
</script>

<style>
.v-data-table__wrapper {
  margin-top: 1rem;
}
</style>
