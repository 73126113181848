<template>
  <!-- #Comments -->
  <v-dialog
    v-model="dialogShowTrackingComment"
    max-width="600px"
    @keydown="closeModalShowTrackingComment"
    @click:outside="closeModalShowTrackingComment"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Comentario</span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="currentTrackingComment">
          <p>
            {{ currentTrackingComment }}
          </p>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" outlined text @click="closeModalShowTrackingComment">
          <small>
            Cerrar
          </small>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogShowTrackingComment: {
      type: Boolean,
      default: false
    },
    closeModalShowTrackingComment: {
      type: Function,
      default: () => {}
    },
    currentTrackingComment: {
      default: null
    }
  }
}
</script>

<style></style>
