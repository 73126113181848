<template>
  <v-card class="mx-auto my-3 pt-3" min-height="235px" tile max-width="1050px">
    <v-row class="" v-if="infoUser">
      <v-col class="py-0" cols="12" md="3" sm="12">
        <v-avatar class="profile" color="grey" size="144" tile>
          <v-img v-if="infoUser.photo_url" :src="infoUser.photo_url"></v-img>
          <span v-else="" class="white--text text-h2">{{ infoUser.name.charAt(0) }}</span>
        </v-avatar>
        <v-list-item>
          <v-list-item-content class="mt-2 pb-0">
            <v-list-item-title class="text-h6">
              {{ infoUser.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="py-0 mt-1" cols="12" md="4" sm="12">
        <v-col cols="12" md="12" sm="12">
          <v-card-subtitle class="pb-0 font-weight-bold">
            Correo electrónico:
          </v-card-subtitle>
          <v-card-text class="">
            <div>{{ infoUser.email }}</div>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-card-subtitle class="pb-0 font-weight-bold">
            RUT:
          </v-card-subtitle>
          <v-card-text class="">
            <div>{{ infoUser.rut ? infoUser.rut : 'Sin RUT' }}</div>
          </v-card-text>
        </v-col>
      </v-col>
      <v-col class="py-0" cols="12" md="5" sm="12">
        <v-row class="mt-1">
          <v-col cols="12" md="6" sm="12">
            <v-card-subtitle class="pb-0 font-weight-bold">
              Área de trabajo:
            </v-card-subtitle>
            <v-card-text class="">
              <div>
                {{
                  userActiveContract && userActiveContract.org_unit
                    ? userActiveContract.org_unit
                    : 'Sin área de trabajo '
                }}
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-card-subtitle class="pb-0 font-weight-bold">
              Cargo:
            </v-card-subtitle>
            <v-card-text class="">
              <div>
                {{
                  userActiveContract && userActiveContract.position
                    ? userActiveContract.position
                    : 'Sin área de trabajo '
                }}
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <v-card-subtitle class="pb-0 pl-0 font-weight-bold">
            Contrato actual:
          </v-card-subtitle>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12" md="6" sm="12" class="p-0">
                <v-card-subtitle class="pb-0 pl-0 font-weight-bold">
                  Desde:
                </v-card-subtitle>
                <v-card-text class="pl-0">
                  <div>
                    {{
                      userActiveContract && userActiveContract.since
                        ? formatDateToHumanMethod(userActiveContract.since)
                        : 'Sin contrato'
                    }}
                  </div>
                </v-card-text>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="p-0">
                <v-card-subtitle class="pb-0 pl-3 font-weight-bold">
                  Hasta:
                </v-card-subtitle>
                <v-card-text class="">
                  <div>
                    {{
                      !userActiveContract
                        ? 'Sin contrato'
                        : userActiveContract && userActiveContract.until
                        ? formatDateToHumanMethod(userActiveContract.until)
                        : 'Indefinido '
                    }}
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-col>
    </v-row>
    <section class="text-center pt-5" v-else>
      <v-progress-circular
        class="mt-5"
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
      <p class="mt-2">Cargando..</p>
    </section>
  </v-card>
</template>

<script>
import { formatDateToHuman } from '@/helpers/common'

export default {
  props: {
    infoUser: {
      default: null
    },
    userActiveContract: {
      default: null
    }
  },
  methods: {
    formatDateToHumanMethod(date) {
      return formatDateToHuman(date)
    }
  }
}
</script>

<style></style>
