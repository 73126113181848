<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-card class="mx-auto my-3 px-5 py-3" max-width="1050px">
      <v-row class="fill-height" v-if="trackingSummary">
        <v-col cols="12" md="3" sm="12">
          <v-col cols="12" md="12" sm="12">
            <v-toolbar-title class="p-3 pt-0 font-weight-bold"
              >Resumen</v-toolbar-title
            >
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              HH del día:
            </v-card-subtitle>
            <v-card-text class="">
              <div>{{ trackingSummary.hours_today }}</div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              HH de la semana:
            </v-card-subtitle>
            <v-card-text class="">
              <div>{{ trackingSummary.hours_week }}</div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              HH del mes:
            </v-card-subtitle>
            <v-card-text class="">
              <div>{{ trackingSummary.hours_month }}</div>
            </v-card-text>
          </v-col>
        </v-col>
        <v-col cols="12" md="9" sm="12">
          <v-toolbar-title class="text-center p-3 mb-3 font-weight-bold text-xs"
            >Estadisticas de HH por proyecto
          </v-toolbar-title>
          <v-row justify="center" class="px-5">
            <v-col cols="12" md="6" sm="12">
              <v-menu
                v-model="dateYear"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.year"
                    label="Filtro por año"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click="saveYear"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.year"
                  ref="pickerYear"
                  no-title
                  scrollable
                  :max="new Date().toISOString().split('T')[0]"
                  @click:year="(year) => saveYear(year)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-menu
                v-model="dateMonth"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.monthFormatted"
                    label="Filtro por mes"
                    prepend-icon="mdi-calendar"
                    readonly
                    :disabled="!filters.year"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click="saveMonth"
                  ></v-text-field>
                </template>
                <v-date-picker
                  id="pickerMonth"
                  v-model="filters.month"
                  ref="pickerMonth"
                  no-title
                  type="month"
                  scrollable
                  @click:month="(month) => saveMonth(month)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-autocomplete
                label="Filtro por centro de costo"
                v-model="filters.project_code"
                :items="projects"
                clearable
                item-text="custom_code"
                item-value="code"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-autocomplete
                label="Filtro por profesional"
                v-model="filters.professional"
                :items="professionals"
                clearable
                item-text="name"
                item-value="email"
              >
                <template v-slot:item="{ on, attrs, item }">
                  <p class="m-3">
                    {{ item.name }} <small>({{ item.email }})</small>
                    <br />
                    <span style="font-size: 12px; color: gray">{{
                      item.rut ? item.rut : "Sin RUT registrado"
                    }}</span>
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <bar-chart
            v-if="ifCountHHProjects"
            ref="chartTrackingSummary"
          ></bar-chart>
          <v-toolbar-title
            class="d-flex mt-5 align-items-center justify-content-center"
            v-else
          >
            <p>
              ¡ Sin HH actualmente para mostrar !
            </p>
          </v-toolbar-title>
        </v-col>
        <v-col cols="12" md="12" sm="12"> </v-col>
      </v-row>
      <section class="text-center pt-5" v-else>
        <v-progress-circular
          class="mt-5"
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
        <p class="mt-2">Cargando..</p>
      </section>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import BarChart from "../../charts/chartjs/BarChart.vue";
import { MAP_MONTHS } from "@/constants/common";

export default {
  components: { BarChart },
  data() {
    return {
      loading: false,
      dateYear: false,
      dateMonth: false,
      filters: {
        year: null,
        month: null,
        monthFormatted: null,
        project_code: null,
        professional: null,
      },
    };
  },
  mounted() {
    this.getProfessionals();
  },
  methods: {
    ...mapActions({
      getTrackingSummary: "tracking/getTrackingSummary",
      getProfessionals: "tracking/getProfessionals",
    }),
    saveYear(year) {
      setTimeout(() => {
        this.$refs.pickerYear.internalActivePicker = "YEAR";
      }, 100);
      if (!isNaN(year)) {
        this.filters.year = `${year}`;
        this.dateYear = false;
        this.getTrackingSummaryData();
      }
    },
    resetFilterMonth() {
      this.filters.month = null;
      this.filters.monthFormatted = null;
    },
    resetFilterYear() {
      this.filters.year = null;
    },
    saveMonth(month) {
      setTimeout(() => {
        this.$refs.pickerMonth.internalActivePicker = "MONTH";
      }, 100);
      if (month && !month.type) {
        this.filters.month = month;
        this.filters.monthFormatted = MAP_MONTHS[month.split("-")[1]];
        this.dateMonth = false;
        this.getTrackingSummaryData();
      }
    },
    async getTrackingSummaryData() {
      await this.getTrackingSummary({
        user: this.filters.professional
          ? this.filters.professional
          : this.user.email,
        year: this.filters.year ? this.filters.year : undefined,
        month: this.filters.month
          ? +this.filters.month.split("-")[1]
          : undefined,
        ceco: this.filters.project_code ? this.filters.project_code : undefined,
      });
      this.setDataChartTrackingSummary();
    },
    getPercentageHH(totalHH) {
      if (totalHH && this.trackingSummary.workdays_this_month) {
        return (
          (totalHH / (this.trackingSummary.workdays_this_month * 8)) *
          100
        ).toFixed(2);
      }
      return 0;
    },
    setDataChartTrackingSummary() {
      let self = this;
      if (this.ifCountHHProjects) {
        this.$refs.chartTrackingSummary.renderChart(
          {
            labels: Object.keys(
              this.filters.year ||
                this.filters.professional ||
                this.filters.project_code
                ? this.trackingSummary.hours_per_project
                : this.trackingSummary.hours_per_project_per_month
            ),
            datasets: [
              {
                axis: "y",
                data: Object.values(
                  this.filters.year ||
                    this.filters.professional ||
                    this.filters.project_code
                    ? this.trackingSummary.hours_per_project
                    : this.trackingSummary.hours_per_project_per_month
                ),
                backgroundColor: [
                  "#0D47A1",
                  "#536DFE",
                  "#26C6DA",
                  "#CDDC39",
                  "#00E676",
                  "#FFCA28",
                  "#4527A0",
                  "#FFEB3B",
                  "#607D8B",
                  "#6D4C41",
                ],
                hoverBackgroundColor: [
                  "#0D47A1",
                  "#536DFE",
                  "#26C6DA",
                  "#CDDC39",
                  "#00E676",
                  "#FFCA28",
                  "#4527A0",
                  "#FFEB3B",
                  "#607D8B",
                  "#6D4C41",
                ],
                hoverBorderColor: "#fff",
                borderWidth: 1,
                radius: "60%",
                fill: false,
                innerRadius: "25%",
              },
            ],
          },
          {
            indexAxis: "y",
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  display: true,
                  stacked: true,
                  ticks: {
                    min: 0, // minimum value
                  },
                },
              ],
            },
            legend: {
              position: "bottom",
              onClick: (e) => e.stopPropagation(),
              labels: {
                padding: 30,
                usePointStyle: false,
                fontSize: 12,
                generateLabels: (chart) => {
                  return chart.data.labels.map((l, i) => ({
                    datasetIndex: i,
                    text:
                      `${l}: ${chart.data.datasets[0].data[i]} HH` +
                      ` ${`(${this.getPercentageHH(
                        chart.data.datasets[0].data[i]
                      )}%)`}`,
                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                    hidden: false,
                    fontColor: "rgb(255, 99, 132)",
                  }));
                },
              },
            },
          }
        );
      }
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      trackingSummary: "tracking/trackingSummary",
      projects: "project/projects",
      professionals: "tracking/professionals",
    }),
    ifCountHHProjects() {
      if (this.trackingSummary) {
        return (
          Object.keys(
            this.filters.year ||
              this.filters.professional ||
              this.filters.project_code
              ? this.trackingSummary.hours_per_project
              : this.trackingSummary.hours_per_project_per_month
          ).length > 0
        );
      }
      return false;
    },
    ifCountHHSummary() {
      if (this.trackingSummary) {
        return (
          this.trackingSummary.hours_today ||
          this.trackingSummary.hours_week ||
          this.trackingSummary.hours_month
        );
      }
      return false;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler: async function(newUser) {
        if (newUser) {
          this.getTrackingSummaryData();
        }
      },
    },
    "filters.monthFormatted": function(month) {
      if (!month) {
        this.resetFilterMonth();
        this.getTrackingSummaryData();
      }
    },
    "filters.year": function(year) {
      if (!year) {
        this.resetFilterYear();
        this.resetFilterMonth();
        this.getTrackingSummaryData();
      }
    },
    "filters.project_code": function(code) {
      this.getTrackingSummaryData();
    },
    "filters.professional": function(email) {
      this.getTrackingSummaryData();
    },
  },
};
</script>

<style>
#pickerMonth .v-date-picker-header {
  display: none !important;
}
</style>
